@import "colors.less";

// Typography
h1,
h2 {
  &.ant-typography {
    font-weight: 700;
  }
}

.ant-form {
  .ant-input {
    font-weight: 500;
  }
  .ant-form-item-label {
    & > label {
      color: @input-label;
      font-weight: 500;
      font-family: "Inter";
    }
  }

  .ant-input:placeholder-shown,
  .ant-picker .ant-picker-input > input:placeholder-shown {
    font-weight: 400;
  }

  .ant-checkbox-wrapper .ant-checkbox + span {
    color: @checkbox-radio-label;
    font-weight: 400;
  }

  .ant-form-horizontal .ant-form-item-label {
    padding: 0px;
  }
  .ant-form-item {
    margin-bottom: 21px;
    .ant-form-item-control-input {
      min-height: unset;
    }
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker,
.ant-input,
.ant-input-number {
  border-width: 1px;
}

.ant-input-affix-wrapper {
  height: 36px;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector,
.ant-form input[type="file"]:focus,
.ant-picker-focused,
.ant-input-number-focused,
.ant-input:focus {
  border-color: @input-focus-border;
  box-shadow: unset;
}
.ant-modal .ant-modal-close:focus-visible,
.ant-checkbox .ant-checkbox-input:focus-visible + .ant-checkbox-inner,
.ant-radio-wrapper .ant-radio-input:focus-visible + .ant-radio-inner {
  outline: 1px solid @outline;
  outline-offset: 0px;
  border: none;
}

.ant-radio-button-wrapper:has(:focus-visible) {
  outline: 1px solid @outline;
  outline-offset: -1px;
}
.ant-radio-button-wrapper:last-child:has(:focus-visible) {
  outline-offset: 0px;
}

.ant-table {
  .ant-table-thead tr th,
  .ant-table-thead tr td {
    background: @white;
    color: @table-header;
    padding: 12px 4px;
    font-weight: 500;
    font-size: 13px;
    &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      content: none;
    }
  }
  .ant-table-tbody tr td {
    strong {
      color: @table-data-strong;
    }
    color: @table-data;
    padding: 4px;
  }
  .ant-table-tbody tr td,
  .ant-table-thead tr th,
  .ant-table-thead tr td {
    border-bottom: 1px solid @table-border;
    &:first-child {
      padding-left: 24px;
    }
  }
  .ant-table-container:has(.ant-table-body:focus-visible) table {
    border-collapse: collapse;
  }
  .ant-table-body:focus-visible {
    outline: 1px solid @table-body-focus-outline;
    outline-offset: 0px;
  }

  .ant-table-thead th.ant-table-column-sort .ant-table-column-title div span.column-title,
  .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-column-title div span.column-title {
    color: @table-header-hover;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-column-title div span.sortIcon,
  .ant-table-thead th.ant-table-column-sort.ant-table-column-has-sorters .ant-table-column-title div span.sortIcon {
    opacity: 1;
  }
  .ant-table-thead th.ant-table-column-has-sorters .ant-table-column-title div span.sortIcon {
    font-size: 12px;
    color: @table-header-sort-icon;
    opacity: 0;
  }
}

.ant-modal-root {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-footer {
    padding: 10px 16px;
  }
  .ant-modal-close {
    top: 37px;
    .ant-modal-close-x {
      font-size: 15px;
    }
  }

  .ant-modal-header {
    padding: 40px 30px 16px 30px;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
    .ant-modal-title {
      font-size: 1.125rem; //18px
      font-weight: 700;
    }
  }
}

.ant-modal-root.secondary {
  .ant-modal-header {
    padding: 23px 30px 0px 30px;
  }
  .ant-modal-close {
    top: 18px;
  }
  .ant-modal-body {
    padding: 23px 30px;
  }
}

.ant-select-selection-item .ant-checkbox {
  display: none;
}
.ant-select-dropdown .ant-select-item-option.ant-select-item-option-selected {
  background-color: transparent;
  .ant-select-item-option-state {
    display: none;
  }
}

.ant-select-dropdown .rc-virtual-list .rc-virtual-list-holder {
  overflow-x: hidden;
}

.ant-select-dropdown {
  .ant-select-item-option-content {
    font-weight: 500;
    label.ant-checkbox-wrapper {
      position: absolute;
      z-index: -1;
      width: 100%;

      span:last-child {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.ant-select-selector .ant-select-selection-overflow {
  margin-left: 11px;
}

.ant-select-selector .ant-select-selection-item,
.ant-input-affix-wrapper > input.ant-input,
.ant-picker .ant-picker-input input {
  font-weight: 500;
}

.ant-picker .ant-picker-suffix {
  color: @ant-picker-suffix;
}

.ant-checkbox-wrapper {
  .ant-checkbox-inner {
    background-color: transparent;
    border-radius: 2px;
    border-width: 1.5px;
    border-color: @checkbox-radio-border;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-inner:after {
    border-color: @checkbox-radio-checked-border;
  }
  .ant-checkbox-disabled .ant-checkbox-inner,
  .ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: @checkbox-radio-border;
  }
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover {
  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: transparent;
      border-color: @checkbox-radio-checked-border;
    }
  }
  .ant-checkbox.ant-checkbox-checked:after {
    border-radius: 2px;
  }

  .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled {
    .ant-checkbox-inner {
      border-color: @checkbox-radio-disabled-border;
    }
  }
}

.ant-radio-wrapper {
  .ant-radio-disabled .ant-radio-inner,
  .ant-radio-inner {
    border-width: 1.5px;
    border-color: @checkbox-radio-disabled-border;
  }

  .ant-radio-disabled .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner {
    background-color: transparent;
  }

  .ant-radio-disabled.ant-radio-checked .ant-radio-inner::after,
  .ant-radio-disabled .ant-radio-inner::after {
    transform: scale(0.4);
    background-color: @checkbox-radio-disabled-border;
  }

  .ant-radio-inner::after {
    transform: scale(0.4);
    background-color: @checkbox-radio-checked-border;
  }
}

.ant-popover .ant-popover-title {
  min-width: unset;
  margin-bottom: 0;
  font-weight: 400;
}

.ant-popover.popover-date-range-picker .ant-popover-inner {
  background-color: @white;
}

.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected {
  .ant-picker-time-panel-cell-inner:hover,
  .ant-picker-time-panel-cell-inner {
    background: @ant-picker-inner-panel-bg;
    color: @white;
  }
}

.ant-radio-group {
  .ant-radio-button-wrapper span {
    font-weight: 500;
    color: @checkbox-radio-label;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover,
  .ant-radio-button-wrapper:hover {
    color: @radio-button-checked;
    background: @radio-button-checked-bg;
    border-color: @radio-button-checked-border;
  }
  .ant-radio-button-wrapper:hover {
    background: @radio-button-wrapper-bg-hover;
    z-index: 1;
  }
  .ant-radio-button-wrapper:not(:first-child):hover::before,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: @radio-button-checked-border;
  }
}

.ant-input-number {
  height: 36px;
  .ant-input-number-input {
    font-weight: 500;
    color: @input-color;
  }
}

.ant-notification .ant-notification-notice {
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 15px;
  }
  .ant-notification-notice-icon-success.anticon {
    color: @notification-success;
  }
  .ant-notification-notice-icon-error.anticon {
    color: @notification-error;
  }
  .ant-notification-notice-icon-info.anticon {
    color: @notification-info;
  }
}

.ant-notification-topRight.ant-notification:has(> .notification-bar) {
  bottom: auto;
  top: 0 !important;
  left: 246px;
  right: 0;
  margin: 0;
  height: 68px;
  background-color: @notification-success-bg;

  .ant-notification-notice.notification-bar {
    border-radius: 0;
    width: 100%;
    box-shadow: none;
    padding: 23px 34px;
    background-color: @notification-success-bg;
    margin: 0;

    .ant-notification-notice-message {
      margin-bottom: 0;
    }
  }
}

h1.ant-typography.table-title,
h2.ant-typography.table-title {
  font-size: 15px;
  font-weight: 600;
}

.section-box-shadow {
  box-shadow: 10px 10px 20px 0px @box-shadow;
}

.popover-date-range-picker.ant-popover .ant-popover-inner {
  background-color: @white;
}

.ant-segmented {
  padding: 4px;
  .ant-segmented-item-label {
    font-size: 12px;
    font-weight: 500;
    min-height: 26px;
    line-height: 26px;
  }
}

.ant-list.ant-list-bordered {
  border: 1px solid @list-bordered;
  border-radius: 6px;
}
